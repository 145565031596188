/* Imports */
import { getSessionInfo } from '@js/helpers/Craft';

class UserSession {
    constructor() {
        this.intervalId = null; // Store the interval ID for later reference
    }

    /**
     * Start polling
     *
     * @param interval The number in seconds for the interval to wait
     */
    startPolling(interval = 60) {
        console.log('Start polling');
        // Start the polling process with the specified interval (default 5 seconds)
        this.intervalId = setInterval(async () => {
            await this.fetchSessionInfo();
        }, interval * 1000);
    }

    async fetchSessionInfo() {
        try {
            const sessionInfo = await getSessionInfo();
            console.log('Session Info:');
            console.log({sessionInfo});

            // Stop polling if the timeout is less than or equal to 0
            if (sessionInfo.isGuest) {
                this.stopPolling();
                console.log('Polling stopped due to user being a guest');

                return;
            }

            // TODO: Add a popup which shows the user they have 2 minutes before being logged out.
            // Add in a way for them to to increase their user session time.

            // Stop polling if the timeout is less than or equal to 0
            if (sessionInfo.timeout <= 0) {
                this.stopPolling();
                console.log('Polling stopped due to timeout.');

                // TODO: Add pop up saying the users session has ended.
                // Clear the countdown popup.
            }
        } catch (error) {
            console.error('Error fetching session info:', error);

            // Optionally, stop polling on error, depending on the use case
            // this.stopPolling();
        }
    }

    /**
     * Stop polling
     */
    stopPolling() {
        // Clear the interval if it's running
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
}

export default new UserSession();
