/* Plugin imports */
import Alpine from "alpinejs";

class CheckUsername {
    constructor() {
        this.dataAttr = 'data-username-check';

        document.addEventListener('alpine:init', () => {

            // Magic: $checkUsername
            Alpine.magic('checkUsername', el => {
                this._check(el);
            });

            // Directive: x-checkUsername
            Alpine.directive('checkUsername', (el) => {
                this._check(el);
            });

        });
    }

    _check(el) {
        let usernameVal = el.value,
            usernameLength = el.value.length,
            parentContainer = el.parentElement.parentElement;

        if (usernameLength >= 3) {
            parentContainer.setAttribute(this.dataAttr, 'loading');

            fetch('/api/v1/check-username.json?username='+usernameVal, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    // If code is not available or invalid
                    if (data.response === 'available') {
                        parentContainer.setAttribute(this.dataAttr, 'available');
                    }
                    else {
                        parentContainer.setAttribute(this.dataAttr, 'not-available');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    parentContainer.setAttribute(this.dataAttr, 'none');
                });
        }
        else {
            parentContainer.setAttribute(this.dataAttr, 'none');
        }
    }
}

export default new CheckUsername();
