/**
 * Fetches session information, including potentially a CSRF token, from the server.
 * Uses async/await for better readability and includes error handling.
 *
 * @returns {Promise<Object>} The session information as a JSON object.
 * @throws Will throw an error if the network request fails or the response is not OK.
 */
export async function getSessionInfo() {
    try {
        // Make a GET request to the session-info endpoint, specifying that we expect a JSON response.
        const response = await fetch('/actions/users/session-info?dontExtendSession=1', {
            method: 'GET',
            headers: {
                'Accept': 'application/json', // Specify that we want a JSON response
                'X-Requested-With': 'XMLHttpRequest' // Important to indicate this is an AJAX request
            }
        });

        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
            throw new Error('Network response was not ok'); // Throw an error if the response is not OK
        }

        // Parse and return the response body as JSON
        return response.json();
    } catch (error) {
        // Log the error to the console for debugging purposes
        console.error('Failed to fetch session info:', error);

        // Re-throw the error to allow the caller to handle it
        throw error;
    }
}
